class Toggler {
    constructor(trigger) {
        this.$trigger = $(trigger);
        this.defaults = {
        };
        this.initials = this.$trigger.data('toggler');

        if (typeof this.initials != 'object') {
            this.initials = {group: this.initials}
        }

        this.options = $.extend({}, this.defaults, this.initials);
        this.$targets = $(`[data-toggler-target="${this.options.group}"]`);

        this.init();
    }

    init() {
        let
            _self = this,
            _opts = _self.options,
            triggerType = _self.$trigger.attr('type');

        switch (triggerType) {
            case 'radio':
                _self.$trigger.on('change', function (e) {
                    //Обработка каждого зависимого элемента
                    _self.$targets.each(function (i, target) {
                        let
                            $target = $(target),
                            targetOpts = $target.data('toggler-target');

                        if (targetOpts.class) {
                            $target.toggleClass(targetOpts.class);
                        }
                        if (targetOpts.fx) {
                            _self.runFx($target, targetOpts.fx,_self.options.action)
                        }
                    });
                });
                break;
            default:
                _self.$trigger.on('click', function (e) {
                    e.preventDefault();
                    // Смена текста на триггере
                    if (_opts.text) {
                        let tempText = _self.$trigger.html();
                        _self.$trigger.html(_opts.text);
                        if (tempText === _self.$trigger.attr('title')) {
                            console.log(_opts.text);
                            _self.$trigger.attr('title', _opts.text);
                        }
                        _opts.text = tempText;
                    }
                    //Обработка каждого зависимого элемента
                    _self.$targets.each(function (i, target) {
                        let
                         $target = $(target),
                            targetOpts = $target.data('toggler-target');

                        if (targetOpts.class) {
                            $target.toggleClass(targetOpts.class);
                        }
                        if (targetOpts.fx) {
                            _self.runFx($target, targetOpts.fx)
                        }
                    });
                });

                break;
        }

    }

    runFx($target, fx, action) {
        let _self = this;
        fx = fx || 'slide';
        action = action || 'toggle';

        switch (fx) {
            case 'fade':
                switch (action){
                    case "toggle":
                        $target.fadeToggle({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                    case "open":
                        $target.fadeIn({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                    case "close":
                        $target.fadeOut({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                }
                break;
            case 'slide':
                switch (action){
                    case "toggle":
                        $target.slideToggle({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                    case "open":
                        $target.slideDown({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                    case "close":
                        $target.slideUp({
                            complete: _self.fxOnComplete($target)
                        });
                        break;
                }
                break;
        }

    }

    fxOnComplete(target) {
    }
}

$(`[data-toggler-target]`).each(function (i, target) {
    let $target = $(target);
    $target.attr('data-toggler-target', $target.data('toggler-target').group)
});

$('[data-toggler]').each(function (i, el) {
    new Toggler(el);
});
